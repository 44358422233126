<template>
	<div>
		<div class="container-fluid py-5">
			<div class="row">
				<div class="col-6 col-sm-6 col-lg-6 col-sm-12 col-xs-12">
					<div class="card">
						<div class="card-body">
							<h1>New Referral Code</h1>

							<form @submit.prevent="createReferralCode">
								<div class="form-group">
									<label for="" class="form-label">Name of Campaign</label>
									<input
										type="text"
										v-model.trim="newPromoCode.name"
										class="form-control"
										placeholder=""
										/>
								</div>
								<div class="form-group">
									<label for="" class="form-label">Promo code prefix</label>
									<input
										type="text"
										v-model.trim="newPromoCode.promo_code_prefix"
										class="form-control"
										placeholder="Enter a custom prefix (optional)"
										/>
								</div>
								<div class="form-group">
									<label for="" class="form-label"
										>Description of referral code Campaign</label
										>
									<textarea
										v-model.trim="newPromoCode.description"
										class="form-control"
										rows="2"
										></textarea>
								</div>

								<div class="form-group">
									<label for="" class="form-label"
										>How many times should the Referral Code generated for this
										campaign be used?</label
										>
									<div>
										<div>
											<label for="" class="form-label"
												><input type="radio" name="promo_code_usages" />
												<div class="d-inline-block ml-2">
													<div class="input-group input-group-sm">
														<input
															type="number"
															min="0"
															class="form-control"
															v-model.number="newPromoCode.max_number_of_uses"
															placeholder="e.g 2"
															/>
														<div class="input-group-append">
															<span class="input-group-text">times</span>
														</div>
													</div>
												</div>
											</label>
										</div>
										<div>
											<label
												for="promo_code_usages_unlimited"
												class="form-label"
												><input
													type="radio"
													name="promo_code_usages"
													id="promo_code_usages_unlimited"
													v-model.number="newPromoCode.max_number_of_uses"
													value="0"
													/>
												Unlimited
											</label>
										</div>
									</div>
								</div>

								<div class="form-group">
									<label for="" class="form-label"
										>How do you want to reward the customers that use this
										referral code?</label
										>
									<select
										name="reward_kind"
										v-model="newPromoCode.reward_kind"
										class="custom-select"
										>
										<option value="wallet_credit">
											Credit Customer's Wallet
										</option>
										<option value="flat_rate_discount_on_trips">
											Discount Customer's Trip by an Amount
										</option>
										<option value="percentage_discount_on_trips">
											Discount Customer's Trip by a Percentage
										</option>
									</select>
								</div>

								<div class="form-group">
									<label for="" class="form-label">{{
										rewardAmountLabel
									}}</label>
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text">{{
												rewardAmountLabelSymbol
											}}</span>
										</div>
										<input
											type="number"
											min="0"
											v-model.number="newPromoCode.reward_amount"
											class="form-control"
											placeholder=""
											/>
									</div>
								</div>

								<div class="form-group">
									<label for="" class="form-label"
										>Which customers can use this referral code?</label
										>
									<select
										name="reward_kind"
										v-model="newPromoCode.customer_constraint"
										class="custom-select"
										>
										<option value="none">All Customers</option>
										<option value="new_customer">Only New Customers</option>
									</select>
								</div>

								<div class="form-group">
									<label for="" class="form-label"
										>When should this referral code expire?</label
										>
									<div>
										<div>
											<label for="" class="form-label"
												><input
														type="radio"
														value="1"
														id="should_expire_yes"
														name="should_expire"
														/>
												<div class="d-inline-block ml-2">
													<v-datepicker
														v-model="newPromoCode.expires_at"
														></v-datepicker>
												</div>
											</label>
										</div>
										<div>
											<label for="should_expire_no" class="form-label"
												><input
													type="radio"
													name="should_expire"
													id="should_expire_no"
													:checked="newPromoCode.expires_at == null"
													@click="newPromoCode.expires_at = null"
													value="0"
													/>
												Never expires
											</label>
										</div>
									</div>
								</div>

								<div class="form-group">
									<label for="" class="form-label"
										>Which of the existing customer is this referral code
										for?</label
										>
									<div>
										<div>
											<label for="owner_type_customers" class="form-label"
												><input
													type="radio"
													v-model="newPromoCode.owner_type"
													value="customer"
													id="owner_type_customers"
													name="owner_type"
													/>
											</label>
											<div class="d-inline-block w-75 ml-2">
												<multiselect
													v-model="newPromoCode.owners"
													id="ajax"
													:custom-label="fullName"
													track-by="id"
													placeholder="Type to search"
													open-direction="bottom"
													:options="customersList"
													:multiple="true"
													:searchable="true"
													:loading="isLoading"
													:internal-search="false"
													:clear-on-select="false"
													:close-on-select="false"
													:options-limit="300"
													:limit="10"
													:limit-text="limitText"
													:show-no-results="false"
													:hide-selected="true"
													@search-change="asyncFindCustomers"
													>
													<template slot="clear" slot-scope="props">
														<div
															class="multiselect__clear"
															v-if="newPromoCode.owners.length"
															@mousedown.prevent.stop="clearAll(props.search)"
															></div> </template
														><span slot="noResult"
														>Oops! No customers found. Consider changing the
														search query.</span
														>
												</multiselect>
											</div>
										</div>

										<div>
											<label for="owner_type_all" class="form-label"
												><input
													type="radio"
													name="owner_type"
													id="owner_type_all"
													v-model="newPromoCode.owner_type"
													value="all"
													/>
												Generate for all customers
											</label>
										</div>
									</div>
								</div>

								<div class="form-group">
									<label for="" class="form-label"
										>Do you want to reward the
										{{ newPromoCode.owners.length > 1 ? 'owners' : 'owner' }} of
										{{ newPromoCode.owners.length > 1 ? 'these' : 'this' }}
										referral
										{{ newPromoCode.owners.length > 1 ? 'codes' : 'code' }} when
										other customers use their Referral Code?</label
										>
									<div>
										<label for="should_owner_benefits_yes" class="form-label"
											><input
												type="radio"
												name="should_owner_benefits"
												id="should_owner_benefits_yes"
												@click="
													newPromoCode.owner_benefit.reward_kind =
														'wallet_credit'
												"
												value="wallet_credit"
												:checked="
													newPromoCode.owner_benefit.reward_kind != 'none'
												"
												/>
											Yes
										</label>
									</div>
									<div>
										<label for="should_owner_benefits_no" class="form-label"
											><input
												type="radio"
												name="should_owner_benefits"
												id="should_owner_benefits_no"
												v-model="newPromoCode.owner_benefit.reward_kind"
												value="none"
												/>
											No
										</label>
									</div>
								</div>

								<div v-if="newPromoCode.owner_benefit.reward_kind != 'none'">
									<div class="form-group">
										<label for="" class="form-label"
											>How do you want to reward the referral code owner?</label
											>
										<select
											name="reward_kind"
											v-model="newPromoCode.owner_benefit.reward_kind"
											class="custom-select"
											>
											<option value="wallet_credit">
												Credit Owner's Wallet
											</option>
											<option value="flat_rate_discount_on_trips">
												Discount Owner's Trip by an Amount
											</option>
											<option value="percentage_discount_on_trips">
												Discount Owner's Trip by a Percentage
											</option>
										</select>
									</div>

									<div class="form-group">
										<label for="" class="form-label">{{
											ownerRewardAmountLabel
										}}</label>
										<div class="input-group">
											<div class="input-group-prepend">
												<span class="input-group-text">{{
													ownerRewardAmountLabelSymbol
												}}</span>
											</div>
											<input
												v-model.number="
													newPromoCode.owner_benefit.reward_amount
												"
												type="number"
												min="0"
												class="form-control"
												placeholder=""
												/>
										</div>
									</div>
								</div>

								<div class="form-group">
									<button
										:disabled="sendingRequest"
										type="submit"
										class="btn btn-lg btn-primary"
										>
										{{
											sendingRequest
												? 'Creating Referral Code..'
												: 'Create Referral Code'
										}}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import promoCode from '@/api/promo_codes'
import Multiselect from 'vue-multiselect'
import {ref, computed, watch} from 'vue'
import {useRouter} from 'vue-router/composables'
import {axiosInstance as axios} from '@/plugins/axios'
import Swal from 'sweetalert2'


const router = useRouter()
const shouldRewardOwners = ref(0)
const sendingRequest = ref(false)
const newPromoCode = ref({
  name: '',
  description: '',
  customer_constraint: 'none',
  owner_type: 'customer',
  owner_id: 1,
  owners: [],
  ownerRewardKind: 'wallet_credit',
  ownerRewardAmount: 0,
  expires_at: null,
  max_number_of_uses: 0,
  reward_amount: 0,
  reward_kind: 'wallet_credit',
  owner_reward_amount: 0,
  owner_reward_kind: '',
  should_owner_benefit: false,
  notify_all: false,
  owner_benefit: ref({
    reward_amount: 0,
    reward_kind: 'none'
  })
})
const customersList = ref([])
const isLoading = ref(false)

const ownerHasBenefit = computed(() => {
  return newPromoCode.value.owner_benefit.reward_kind !== 'none'
})


watch(ownerHasBenefit, (val) => {
  newPromoCode.value.should_owner_benefit = val
})

const rewardAmountLabelSymbol = computed(() => {
  if (
    newPromoCode.value.reward_kind == 'wallet_credit' ||
    newPromoCode.value.reward_kind == 'flat_rate_discount_on_trips'
  ) {
    return 'NGN'
  }

  if (newPromoCode.value.reward_kind == 'percentage_discount_on_trips') {
    return '%'
  }

  return 'NGN'
})

const rewardAmountLabel = computed(() => {
  if (newPromoCode.value.reward_kind == 'wallet_credit') {
    return 'How much do you want to reward customers?'
  }

  if (newPromoCode.value.reward_kind == 'flat_rate_discount_on_trips') {
    return 'How much do you want to remove from the trip?'
  }

  if (newPromoCode.value.reward_kind == 'percentage_discount_on_trips') {
    return 'What percentage discount do you want to give on the trip?'
  }

  return 'How much do you want to reward your customers with?'
})

const ownerRewardAmountLabelSymbol = computed(() => {
  if (
    newPromoCode.value.owner_benefit.reward_kind == 'wallet_credit' ||
    newPromoCode.value.owner_benefit.reward_kind ==
    'flat_rate_discount_on_trips'
  ) {
    return 'NGN'
  }

  if (
    newPromoCode.value.owner_benefit.reward_kind ==
    'percentage_discount_on_trips'
  ) {
    return '%'
  }

  return 'NGN'
})

const ownerRewardAmountLabel = computed(() => {
  if (newPromoCode.value.owner_benefit.reward_kind == 'wallet_credit') {
    return 'How much do you want to reward owner?'
  }

  if (
    newPromoCode.value.owner_benefit.reward_kind ==
    'flat_rate_discount_on_trips'
  ) {
    return 'How much do you want to remove from the owner\'s trip?'
  }

  if (
    newPromoCode.value.owner_benefit.reward_kind ==
    'percentage_discount_on_trips'
  ) {
    return 'What percentage discount do you want to give on the owner\'s trip?'
  }

  return 'How much do you want to reward the owner with?'
})




const limitText = (count) => {
  return `and ${count} other customers`
}

const asyncFindCustomers = (query) => {
  if (query) {
    isLoading.value = true
  axios
      .get('/v1/users', { params: { query } })
      .then((response) => {
        customersList.value = response.data
        isLoading.value = false
      })
      .catch((e) => {

        isLoading.value = false
        customersList.value = []
      })
  }
}

const fullName = ({ fname, lname }) => {
  return `${fname} ${lname}`
}

const clearAll = () => {
  newPromoCode.value.owners = []
}

const createReferralCode = async () => {
  if (sendingRequest.value) return
  sendingRequest.value = true
  try {
    const payload = generateNewReferralCodePayload()
    await promoCode.createPromotion(payload)

    Swal.fire({
      icon: 'success',
      title: 'Referral code created',
      text: 'This referral code has been queued to be created',
      showCloseButton: true
    }).then(() => {
      router.push({
        name: 'ListReferralCodes'
      })
    })
  } catch (e) {
    sendingRequest.value = false
    let msg = e.toString()
    if (e && e.response && e.response.data && e.response.data.message) {
      msg = e.response.data.message
    }

    Swal.fire({
      icon: 'error',
      title: 'Promo code creation failed',
      text: msg
    })
  }
}

const generateNewReferralCodePayload = () => {
  const selectedUsers = newPromoCode.value.owners

  if (selectedUsers.length < 1 && newPromoCode.value.owner_type != 'all') {
    throw new Error('Please select at least one user')
  }

  let user_ids = []
  if (newPromoCode.value.owner_type != 'all') {
    user_ids = selectedUsers.map((item) => {
      return { owner_type: 'customer', owner_id: item.id }
    })
  }
  const payload = Object.assign(
    {
      customer_benefit: {
        reward_amount: newPromoCode.value.reward_amount,
        reward_kind: newPromoCode.value.reward_kind,
        customer_constraint: 'none'
      }
    },
    newPromoCode.value,
    {
      owners: user_ids
    }
  )

  if (Object.keys(payload).indexOf('promo_code') > -1) {
    delete payload.promo_code
  }

  if (newPromoCode.value.owner_type === 'all') {
    delete payload.owners
    payload.owner_type = 'all'
    payload.owner_id = 1
  }

  if (
    newPromoCode.value.customer_constraint &&
    newPromoCode.value.customer_constraint != ''
  ) {
    payload.customer_benefit.customer_constraint =
      newPromoCode.value.customer_constraint
  }

  if (
    newPromoCode.value.owner_reward_kind &&
    newPromoCode.value.owner_reward_kind != ''
  ) {
    payload.owner_benefit = {
      reward_amount: newPromoCode.value.owner_reward_amount,
      reward_kind: newPromoCode.value.owner_reward_kind
    }
  }

  if (payload.owner_benefit.reward_kind === 'none') {
    delete payload.owner_benefit
  }

  if (
    Object.keys(payload).indexOf('expires_at') > -1 &&
    !payload.expires_at
  ) {
    delete payload.expires_at
  }

  return payload
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

